import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'chapa',
    loadChildren: () => import('./chapa/chapa.module').then( m => m.ChapaPageModule)
  },
  {
    path: 'monedero',
    loadChildren: () => import('./monedero/monedero.module').then( m => m.MonederoPageModule)
  },
  {
    path: 'barra',
    loadChildren: () => import('./barra/barra.module').then( m => m.BarraPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
